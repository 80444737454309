.filters {

  .breadcrumb-area {
    letter-spacing: 1px;
    position: absolute;
    top: 50%;
    right: 0;
    opacity: 0.9;
    left: 0;
  }

  .breadcrumb-area h1 {
    margin: 0 0 10px;
    font-weight: 700;
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
  }

  .breadcrumbs li {
    display: inline-block;
    list-style: none;
    font-size: 16px;

    font-weight: 500;
    margin: 0;

  }

  .breadcrumbs li a {
    color: #fff;
  }

  .breadcrumbs .active, .breadcrumbs .selected {
    margin-left: 3px;
    color: #fff;
  }

  .see-more, .loading {
    list-style: italic;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  .see-more {
    color: black !important;
  }

  .loading {
    color: white;
  }

  .breadcrumbs .selected:before, .breadcrumbs .active:before {
    content: "/";
    font-family: "FontAwesome";
    font-size: 16px;
    margin-right: 7px;
    font-weight: 600;
  }

  ul.options {
    columns: 2;

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      columns: 3;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      columns: 3;
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      columns: 5;
    }
    break-inside: avoid-column;

    li {
      span {
        color: white;
      }

      list-style: none;
      overflow: hidden;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;


      margin-bottom: 12px;
      @media (min-width: 992px) {
        margin-bottom: 4px;
      }
    }
  }
}