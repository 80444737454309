.special-facilities-list li {
    list-style: none;
    float: left;
    width: 50%;
    font-weight: 400;
    line-height: 35px;
    font-size: 14px;
}
.special-facilities-list li i {
    font-size: 14px;
    font-weight: 500;
}