
.special-container {
  position: relative;
  width: 100%;
  background: #dddddd;
  text-align: center;

  padding: 40px;

  .special-background {
    border-radius: 8px;
    overflow: hidden;
    width: 50%;
    height: auto;
  }

  .special-numbers {
    position: absolute;
    left: 0;
    right: 0;
    top: 32%;
    display: flex;
    justify-content: center;
    align-content: center;

    .special-number {
      height: 22px;
      width: 14px;

      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }
}