.whatsapp-floating-button {
  &.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    background-color: #0084ff;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 9999;


    :hover {
      transition-duration: 0.2s;
      transform: scale(1.05);
    }

    .my-float {
      margin-top: 16px;
    }
  }
}

.whatsapp-floating-button--contact-button {
  span {
    color: inherit;
  }

  border: 0;
  border-radius: 8px;
  color: white;
  background: #f0151f;

  &:hover {
    background: #0084ff;
  }
}