.language-ar, .language-fr {
  .language-text-align {
    text-align: right;
  }

  .language-text-dir {
    direction: rtl;
  }

  .language-justify-content {
    justify-content: flex-end;
  }

  .language-flex-direction {
    flex-direction: row-reverse;
  }

  .language-flex-direction-ar {
    flex-direction: row-reverse;
  }

  .justify-content-flex-start-ar{
    justify-content: flex-start;
  }

  .language-flex-direction-reverse {
    flex-direction: row;
  }

  .m-language-2 {
    margin-left: .5rem !important;
  }
}

.language-en {
  .language-text-align {
    text-align: left;
  }

  .language-text-dir {
    direction: ltr;
  }

  .language-justify-content {
    justify-content: flex-start;
  }

  .language-flex-direction {
    flex-direction: row;
  }

  .language-flex-direction-reverse {
    flex-direction: row-reverse;
  }

  .m-language-2 {
    margin-right: .5rem !important;
  }
}

.lang-en {
  direction: ltr;
  text-align: left;
}

.lang-en-dir {
  direction: ltr;
}