.sidebar-right {
  .sidebar-title {

    .sidebar-title--icon {
      margin-right: 4px;
    }

    &.collapsed {
      margin: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
}