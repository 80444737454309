.feedback-no-result-found {
  padding: 32px 94px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .feedback-no-result-found--icon {
    width: 100px;
    height: 100px;

    margin-bottom: 24px;
  }

  .feedback-no-result-found--text {
    //@include font-body-lg-medium;
    //color: $headings;
    margin-bottom: 8px;
  }

  .feedback-no-result-found--details {
    //@include font-body-sm-medium;
    //color: $body-secondary;
  }
}
