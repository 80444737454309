.language-switcher {
  position: relative;
  display: block;
  height: 44px;
  width: 100px;

  .custom-sel {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .selected {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .custom-sel span {
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: 10px;
    color: #546e7a;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDF0F2;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: #ffffff;
      background-color: #f0151f;
    }

    .flag {
      width: 24px;
      height: 16px;
      margin: 0 4px;
    }
  }


  .custom-sel span.selected {
    background-color: transparent;
    color: #f0151f;
  }

  .custom-sel span.selected:hover {
    background-color: transparent;
    color: #546e7a;
  }

  .hidden {
    display: none !important;
  }

  .lightblue {
    color: #03a9f4;
    margin-left: -4px;
  }

  .show-sel {
    background-color: #ffffff;
    box-shadow: -5px 0px 65px 0px rgba(0, 0, 0, 0.18);
  }

}
