.hideExtraNum{
  display: none !important;
}
.call-dialog-button {
  // Small devices (landscape phones, 576px and up)
  //@media (min-width: 576px) {
    .call-dialog-button--icon {
      width: 40px;
      height: 40px;
    }

    .call-dialog-button--number {
      font-size: 28px;

    }
  //}

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .call-dialog-button--icon {
      width: 36px;
      height: 36px;
    }

    .call-dialog-button--number {
      font-size: 28px;
    }
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    .call-dialog-button--icon {
      width: 26px;
      height: 26px;
    }

    .call-dialog-button--number {
      font-size: 20px;
    }
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    .call-dialog-button--icon {
      width: 24px;
      height: 24px;
    }

    .call-dialog-button--number {
      font-size: 18px;
    }
  }

}

