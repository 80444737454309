.range-slider {
  //span{
  //  color: inherit;
  //}
  .MuiSlider-rail, .MuiSlider-track, .MuiSlider-thumb, .PrivateValueLabel-circle-10 {
    color: #f0151f;
  }

  .PrivateValueLabel-circle-10 {
    color: black !important;
    //width: 50px;
    //height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;

    text-align: center;
    font-size: 8px;
  }

  
}