.filter-select {
  height: 54px;
  width: 100%;
  border-radius: 0;
  border-right: solid 1px #5a5a5a !important;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }

  .MuiSelect-selectMenu {
    //height: 100%;
    padding: 10px;
    height: 24px;
  }

  .MuiMenu-paper {
    transition-duration: 0s !important;
  }
}